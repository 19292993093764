<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" :src='require("@/assets/img/app/banner-bg.png")'>
      <img class="banner-wrightin" :src='require("@/assets/img/app/banner-wrightin.png")'>
      <img class="banner-doctor" :src='require("@/assets/img/app/banner-doctor.png")'>
      <p class="banner-title"></p>
      <p class="banner-subTitle"></p>
      <p class="desc-user">澜渟用户版App</p>
      <p class="desc-doc">澜渟医生版App</p>
      <div class="banner-download wrightin">
        <button class="btn"><img :src='require("@/assets/img/app/user-logo.png")' />下载澜渟App</button>
        <p class="desc-mini">凯格尔训练免费指导平台</p>
      </div>
      <div class="banner-download-hover wrightin">
        <div class="arrow"></div>
        <div class="content">
          <button class="ios" @click='downloadUser("ios")'>iOS下载</button>
          <button class="android" @click='downloadUser("android")'>Android下载</button>
          <img class="qrcode" :src='require("@/assets/img/index/qrcode-app.png")'>
          <p class="qrcode-desc">扫二维码 即刻下载</p>
        </div>
      </div>
      <div class="banner-download doctor">
        <button class="btn"><img :src='require("@/assets/img/app/doc-logo.png")' />下载澜渟医生App</button>
        <p class="desc-mini">盆底康复医生成长进阶之选</p>
      </div>
      <div class="banner-download-hover doctor">
        <div class="arrow"></div>
        <div class="content">
          <button class="ios" @click='downloadDoctor("ios")'>iOS下载</button>
          <button class="android" @click='downloadDoctor("android")'>Android下载</button>
          <img class="qrcode" :src='require("@/assets/img/index/qrcode-app-doctor.png")'>
          <p class="qrcode-desc">扫二维码 即刻下载</p>
        </div>
      </div>
    </div>
    <div id="detail">
      <div class="detail-item trainW" ref="trainW">
        <img class="img" :src='require("@/assets/img/app/detail-trainW.png")'
          :style='{left: trainW.img.left+"rem", opacity: trainW.opacity}'>
        <div class="info" :style='{left: trainW.info.left+"rem", opacity: trainW.opacity}'>
          <p class="title">训练</p>
          <p class="desc">产后康复训练<br />塑造产后更美的你</p>
          <div class="vi-symbol symbol"></div>
        </div>
      </div>
      <div class="detail-item friendW" ref="friendW">
        <img class="img" :src='require("@/assets/img/app/detail-friendW.png")'
          :style='{left: friendW.img.left+"rem", opacity: friendW.opacity}'>
        <div class="info" :style='{left: friendW.info.left+"rem", opacity: friendW.opacity}'>
          <p class="title">盆友圈</p>
          <p class="desc">盆底肌训练圈子<br />见证每一天的进步</p>
          <div class="vi-symbol symbol"></div>
        </div>
      </div>
      <div class="detail-item wiki-wrightin" ref="wikiW">
        <img class="img" :src='require("@/assets/img/app/detail-wikiW.png")'
          :style='{left: wikiW.img.left+"rem", opacity: wikiW.opacity}'>
        <div class="info" :style='{left: wikiW.info.left+"rem", opacity: wikiW.opacity}'>
          <p class="title">百科</p>
          <p class="desc">盆底界小百科<br />轻松获取各种盆底知识</p>
          <div class="vi-symbol symbol"></div>
        </div>
      </div>
      <div class="detail-item meeting-doctor" ref="meetingD">
        <img class="img" :src='require("@/assets/img/app/detail-meetingD.png")'
          :style='{left: meetingD.img.left+"rem", opacity: meetingD.opacity}'>
        <div class="info" :style='{left: meetingD.info.left+"rem", opacity: meetingD.opacity}'>
          <p class="title">会议吧</p>
          <p class="desc">妇产医生进修学习<br />参会考证晋升有道</p>
          <div class="vi-symbol symbol"></div>
        </div>
      </div>
      <div class="detail-item college-doctor" ref="collegeD">
        <img class="img" :src='require("@/assets/img/app/detail-collegeD.png")'
          :style='{left: collegeD.img.left+"rem", opacity: collegeD.opacity}'>
        <div class="info" :style='{left: collegeD.info.left+"rem", opacity: collegeD.opacity}'>
          <p class="title">极致学院</p>
          <p class="desc">海量盆底干货课程学习<br />盆底学术大咖直播分享</p>
          <div class="vi-symbol symbol"></div>
        </div>
      </div>
      <img class="detail-circle" style="top:1.29rem;left:0;"
        :src='require("@/assets/img/app/detail-bg-left.png")'>
      <img class="detail-circle" style="top:8.48rem;right:0;"
        :src='require("@/assets/img/app/detail-bg-right.png")'>
      <img class="detail-circle" style="top:15.67rem;left:0;"
        :src='require("@/assets/img/app/detail-bg-left.png")'>
      <img class="detail-circle" style="top:22.86rem;right:0;"
        :src='require("@/assets/img/app/detail-bg-right.png")'>
      <img class="detail-circle" style="top:30.05rem;left:0;"
        :src='require("@/assets/img/app/detail-bg-left.png")'>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTop: 0,
      scrollDirection: '',
      interval: {
        time: 15,
        distance: .056,
        opacity: 1 / 50,
      },
      trainW: {
        direction: '',
        img: {start: 1.79, end: 5.22, left: 1.79},
        info: {start: 15.03, end: 11.91, left: 15.03},
        opacity: 0,
      },
      friendW: {
        direction: '',
        img: {start: 12.15, end: 9.33, left: 12.15},
        info: {start: 1.79, end: 5.22, left: 1.79},
        opacity: 0,
      },
      wikiW: {
        direction: '',
        img: {start: 1.79, end: 5.22, left: 1.79},
        info: {start: 15.03, end: 11.91, left: 15.03},
        opacity: 0,
      },
      meetingD: {
        direction: '',
        img: {start: 12.15, end: 9.33, left: 12.15},
        info: {start: 1.79, end: 5.22, left: 1.79},
        opacity: 0,
      },
      collegeD: {
        direction: '',
        img: {start: 1.79, end: 5.22, left: 1.79},
        info: {start: 15.03, end: 11.91, left: 15.03},
        opacity: 0,
      },
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    // 让第一屏内容展示出来
    this.animationTrainW('down')
    if (document.documentElement.clientWidth < 1665) {
      this.trainW.img.left = this.trainW.img.end
      this.trainW.info.left = this.trainW.info.end
      this.trainW.opacity = 1

      this.friendW.img.left = this.friendW.img.end
      this.friendW.info.left = this.friendW.info.end
      this.friendW.opacity = 1

      this.wikiW.img.left = this.wikiW.img.end
      this.wikiW.info.left = this.wikiW.info.end
      this.wikiW.opacity = 1

      this.meetingD.img.left = this.meetingD.img.end
      this.meetingD.info.left = this.meetingD.info.end
      this.meetingD.opacity = 1

      this.collegeD.img.left = this.collegeD.img.end
      this.collegeD.info.left = this.collegeD.info.end
      this.collegeD.opacity = 1
    }
    window.addEventListener('resize', () => {
      if (document.documentElement.clientWidth < 1665) {
        this.trainW.img.left = this.trainW.img.end
        this.trainW.info.left = this.trainW.info.end
        this.trainW.opacity = 1

        this.friendW.img.left = this.friendW.img.end
        this.friendW.info.left = this.friendW.info.end
        this.friendW.opacity = 1

        this.wikiW.img.left = this.wikiW.img.end
        this.wikiW.info.left = this.wikiW.info.end
        this.wikiW.opacity = 1

        this.meetingD.img.left = this.meetingD.img.end
        this.meetingD.info.left = this.meetingD.info.end
        this.meetingD.opacity = 1

        this.collegeD.img.left = this.collegeD.img.end
        this.collegeD.info.left = this.collegeD.info.end
        this.collegeD.opacity = 1
      } else {
        this.bindScrollWindow(window.scrollY)
      }
    }, false)
  },
  methods: {
    /**
     监听页面滚动条
     @param
     @return
     */
    bindScrollWindow(scrollTop) {
      if (scrollTop > this.scrollTop) {
        this.scrollDirection = 'down'
      } else {
        this.scrollDirection = 'up'
      }
      this.scrollTop = scrollTop
      this.bindScrollTrainW()
      this.bindScrollFriendW()
      this.bindScrollWikiW()
      this.bindScrollMeetingD()
      this.bindScrollCollegeD()
    },
    /**
     监听页面滚动条-训练澜渟
     @param
     @return
     */
    bindScrollTrainW() {
      if (this.scrollTop > 0) {
        if (this.scrollDirection == 'down') {
          if (this.trainWInterval == undefined) {
            this.animationTrainW('down')
          }
        }
      }
    },
    /**
     监听页面滚动条-盆友圈澜渟
     @param
     @return
     */
    bindScrollFriendW() {
      if (this.scrollTop > this.getAnimationTop('friendW')) {
        if (this.scrollDirection == 'down') {
          if (this.friendW.direction == 'up') {
            clearInterval(this.friendWInterval)
            this.friendWInterval = undefined
          }
          if (this.friendWInterval == undefined) {
            this.animationFriendW('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.friendW.direction == 'down') {
            clearInterval(this.friendWInterval)
            this.friendWInterval = undefined
          }
          if (this.friendWInterval == undefined) {
            this.animationFriendW('up')
          }
        }
      }
    },
    /**
     监听页面滚动条-百科澜渟
     @param
     @return
     */
    bindScrollWikiW() {
      if (this.scrollTop > this.getAnimationTop('wikiW')) {
        if (this.scrollDirection == 'down') {
          if (this.wikiW.direction == 'up') {
            clearInterval(this.wikiWInterval)
            this.wikiWInterval = undefined
          }
          if (this.wikiWInterval == undefined) {
            this.animationWikiW('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.wikiW.direction == 'down') {
            clearInterval(this.wikiWInterval)
            this.wikiWInterval = undefined
          }
          if (this.wikiWInterval == undefined) {
            this.animationWikiW('up')
          }
        }
      }
    },
    /**
     监听页面滚动条-会议吧澜渟医生
     @param
     @return
     */
    bindScrollMeetingD() {
      if (this.scrollTop > this.getAnimationTop('meetingD')) {
        if (this.scrollDirection == 'down') {
          if (this.meetingD.direction == 'up') {
            clearInterval(this.meetingDInterval)
            this.meetingDInterval = undefined
          }
          if (this.meetingDInterval == undefined) {
            this.animationMeetingD('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.meetingD.direction == 'down') {
            clearInterval(this.meetingDInterval)
            this.meetingDInterval = undefined
          }
          if (this.meetingDInterval == undefined) {
            this.animationMeetingD('up')
          }
        }
      }
    },
    /**
     监听页面滚动条-极致学院澜渟医生
     @param
     @return
     */
    bindScrollCollegeD() {
      if (this.scrollTop > this.getAnimationTop('collegeD')) {
        if (this.scrollDirection == 'down') {
          if (this.collegeD.direction == 'up') {
            clearInterval(this.collegeDInterval)
            this.collegeDInterval = undefined
          }
          if (this.collegeDInterval == undefined) {
            this.animationCollegeD('down')
          }
        }
      } else {
        if (this.scrollDirection == 'up') {
          if (this.collegeD.direction == 'down') {
            clearInterval(this.collegeDInterval)
            this.collegeDInterval = undefined
          }
          if (this.collegeDInterval == undefined) {
            this.animationCollegeD('up')
          }
        }
      }
    },

    /**
     获取动画距顶距离
     @param {String} type 动画类型
     @return {Number} 动画距顶距离
     */
    getAnimationTop(type) {
      const baseTop = 5.8 * document.documentElement.clientWidth / 1920 * 100 - document.documentElement.clientHeight
      if (type == 'friendW') {
        return baseTop + this.$refs.friendW.offsetTop + this.$refs.friendW.offsetHeight / 2
      } else if (type == 'wikiW') {
        return baseTop + this.$refs.wikiW.offsetTop + this.$refs.wikiW.offsetHeight / 2
      } else if (type == 'meetingD') {
        return baseTop + this.$refs.meetingD.offsetTop + this.$refs.meetingD.offsetHeight / 2
      } else if (type == 'collegeD') {
        return baseTop + this.$refs.collegeD.offsetTop + this.$refs.collegeD.offsetHeight / 2
      }
      return 0
    },

    /**
     执行动画--训练澜渟
     @param {String} direction 页面滚动方向
     @return
     */
    animationTrainW(direction) {
      this.trainW.direction = direction
      if (direction == 'down') {
        this.trainWInterval = setInterval(() => {
          if (this.trainW.img.left >= this.trainW.img.end) {
            clearInterval(this.trainWInterval)
            return
          }
          this.trainW.img.left += this.interval.distance
          this.trainW.info.left -= this.interval.distance
          this.trainW.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.trainWInterval = setInterval(() => {
          if (this.trainW.img.left <= this.trainW.img.start) {
            clearInterval(this.trainWInterval)
            return
          }
          this.trainW.img.left -= this.interval.distance
          this.trainW.info.left += this.interval.distance
          this.trainW.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
    /**
     执行动画--盆友圈澜渟
     @param {String} direction 页面滚动方向
     @return
     */
    animationFriendW(direction) {
      this.friendW.direction = direction
      if (direction == 'down') {
        this.friendWInterval = setInterval(() => {
          if (this.friendW.img.left <= this.friendW.img.end) {
            clearInterval(this.friendWInterval)
          } else {
            this.friendW.img.left -= this.interval.distance
            this.friendW.info.left += this.interval.distance
            this.friendW.opacity += this.interval.opacity
          }
        }, this.interval.time)
      } else if (direction == 'up') {
        this.friendWInterval = setInterval(() => {
          if (this.friendW.img.left >= this.friendW.img.start) {
            clearInterval(this.friendWInterval)
          } else {
            this.friendW.img.left += this.interval.distance
            this.friendW.info.left -= this.interval.distance
            this.friendW.opacity -= this.interval.opacity
          }
        }, this.interval.time)
      }
    },
    /**
     执行动画--百科澜渟
     @param {String} direction 页面滚动方向
     @return
     */
    animationWikiW(direction) {
      this.wikiW.direction = direction
      if (direction == 'down') {
        this.wikiWInterval = setInterval(() => {
          if (this.wikiW.img.left >= this.wikiW.img.end) {
            clearInterval(this.wikiWInterval)
            return
          }
          this.wikiW.img.left += this.interval.distance
          this.wikiW.info.left -= this.interval.distance
          this.wikiW.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.wikiWInterval = setInterval(() => {
          if (this.wikiW.img.left <= this.wikiW.img.start) {
            clearInterval(this.wikiWInterval)
            return
          }
          this.wikiW.img.left -= this.interval.distance
          this.wikiW.info.left += this.interval.distance
          this.wikiW.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },
    /**
     执行动画--会议吧澜渟医生
     @param {String} direction 页面滚动方向
     @return
     */
    animationMeetingD(direction) {
      this.meetingD.direction = direction
      if (direction == 'down') {
        this.meetingDInterval = setInterval(() => {
          if (this.meetingD.img.left <= this.meetingD.img.end) {
            clearInterval(this.meetingDInterval)
          } else {
            this.meetingD.img.left -= this.interval.distance
            this.meetingD.info.left += this.interval.distance
            this.meetingD.opacity += this.interval.opacity
          }
        }, this.interval.time)
      } else if (direction == 'up') {
        this.meetingDInterval = setInterval(() => {
          if (this.meetingD.img.left >= this.meetingD.img.start) {
            clearInterval(this.meetingDInterval)
          } else {
            this.meetingD.img.left += this.interval.distance
            this.meetingD.info.left -= this.interval.distance
            this.meetingD.opacity -= this.interval.opacity
          }
        }, this.interval.time)
      }
    },
    /**
     执行动画--极致学院澜渟医生
     @param {String} direction 页面滚动方向
     @return
     */
    animationCollegeD(direction) {
      this.collegeD.direction = direction
      if (direction == 'down') {
        this.collegeDInterval = setInterval(() => {
          if (this.collegeD.img.left >= this.collegeD.img.end) {
            clearInterval(this.collegeDInterval)
            return
          }
          this.collegeD.img.left += this.interval.distance
          this.collegeD.info.left -= this.interval.distance
          this.collegeD.opacity += this.interval.opacity
        }, this.interval.time)
      } else if (direction == 'up') {
        this.collegeDInterval = setInterval(() => {
          if (this.collegeD.img.left <= this.collegeD.img.start) {
            clearInterval(this.collegeDInterval)
            return
          }
          this.collegeD.img.left -= this.interval.distance
          this.collegeD.info.left += this.interval.distance
          this.collegeD.opacity -= this.interval.opacity
        }, this.interval.time)
      }
    },

    /**
     页面跳转-下载澜渟
     @param
     @return
     */
    downloadUser(type) {
      if (type == 'ios') {
        window.open('https://itunes.apple.com/cn/app/id1243415475?mt=8')
      } else if (type == 'android') {
        window.open(this.$request.web + '/#/inviteApp/downAll')
      }
    },
    /**
     页面跳转-下载澜渟医生
     @param
     @return
     */
    downloadDoctor(type) {
      if (type == 'ios') {
        window.open('https://itunes.apple.com/cn/app/id1235934988?mt=8')
      } else if (type == 'android') {
        window.open(this.$request.web + '/#/inviteApp/downAll')
      }
    },
  },
  watch: {
    '$parent.scrollTop': {
      handler(newVal, oldVal) {
        if (document.documentElement.clientWidth >= 1665) {
          this.bindScrollWindow(newVal)
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  height: 5.8rem;

  > .banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  > .banner-wrightin {
    position: absolute;
    top: 1.34rem;
    left: 10.17rem;
    width: 2.49rem;
    height: 4.93rem;
  }

  > .banner-doctor {
    position: absolute;
    top: 1.34rem;
    left: 13.12rem;
    width: 2.49rem;
    height: 4.94rem;
  }

  > .banner-title {
    position: absolute;
    z-index: 1;
    top: 1.52rem;
    left: 3.6rem;
    height: .67rem;
    line-height: .67rem;
    font-size: .48rem;
    color: #fff;
  }

  > .banner-subTitle {
    position: absolute;
    z-index: 1;
    top: 2.32rem;
    left: 3.6rem;
    height: .33rem;
    line-height: .33rem;
    font-size: .24rem;
    color: #fff;
  }

  > .desc-user {
    position: absolute;
    color: white;
    font-size: .16rem;
    line-height: .22rem;
    top: 0.97rem;
    left: 10.86rem;
  }

  > .desc-doc {
    position: absolute;
    color: white;
    font-size: .16rem;
    line-height: .22rem;
    top: 0.97rem;
    left: 13.81rem;
  }

  > .banner-download {
    position: absolute;
    z-index: 1;
    top: 4.24rem;
    height: 0.79rem;

    > .btn {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 1.66rem;
      height: .5rem;
      line-height: .5rem;
      border: 0.02rem solid #fff;
      border-radius: .08rem;
      background-color: #9185FF;
      color: white;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;

      > img {
        width: 0.36rem;
        height: 0.36rem;
        margin-left: 0.16rem;
        margin-right: 0.07rem;
      }
    }

    > .desc {
      position: absolute;
      z-index: 2;
      top: .6rem;
      left: 0;
      height: .2rem;
      line-height: .2rem;
      font-size: .14rem;
      color: #fff;
    }

    > .desc-mini {
      position: absolute;
      z-index: 2;
      bottom: 0rem;
      left: 0;
      line-height: .24rem;
      font-size: .14rem;
      color: #fff;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  > .doctor {
    > .btn {
      width: 1.98rem;
    }
  }

  > .banner-download-hover {
    display: none;
    position: absolute;
    z-index: 3;
    top: 5.04rem;
    width: 3.58rem;
    height: 1.7rem;

    > .arrow {
      position: absolute;
      z-index: 4;
      top: -.1rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: .1rem solid;
      border-color: transparent transparent rgba(0, 0, 0, 0.35);
    }

    > .content {
      position: absolute;
      z-index: 4;
      top: .1rem;
      left: 0;
      right: 0;
      height: 1.56rem;
      border-radius: .05rem;
      background-color: rgba(0, 0, 0, 0.35);

      > .ios {
        position: absolute;
        z-index: 5;
        top: .18rem;
        left: .18rem;
        padding-left: .63rem;
        width: 1.89rem;
        height: .52rem;
        text-align: left;
        line-height: .52rem;
        border: .01rem solid #999;
        border-radius: .05rem;
        font-size: .19rem;
        background: url(../assets/img/app/banner-btn-ios.png) no-repeat .25rem center;
        background-size: .28rem .33rem;
        background-color: #363636;
        color: #fff;
        cursor: pointer;
      }

      > .android {
        position: absolute;
        z-index: 5;
        top: .87rem;
        left: .18rem;
        padding-left: .63rem;
        width: 1.89rem;
        height: .52rem;
        text-align: left;
        line-height: .52rem;
        border: .01rem solid #999;
        border-radius: .05rem;
        font-size: .19rem;
        background: url(../assets/img/app/banner-btn-android.png) no-repeat .27rem center;
        background-size: .25rem .3rem;
        background-color: #4f945b;
        color: #fff;
        cursor: pointer;
      }

      > .qrcode {
        position: absolute;
        z-index: 5;
        top: .18rem;
        right: .18rem;
        width: 1.0rem;
        height: 1.0rem;
      }

      > .qrcode-desc {
        position: absolute;
        z-index: 5;
        right: 0;
        width: 1.48rem;
        font-size: .12rem;
        text-align: center;
        line-height: .17rem;
        color: #fff;
        bottom: 0.12rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  > .banner-download.wrightin {
    left: 3.6rem;
    width: 1.66rem;
  }

  > .banner-download-hover.wrightin {
    left: 2.71rem;
  }

  > .banner-download.doctor {
    left: 5.64rem;
    width: 1.98rem;
  }

  > .banner-download-hover.doctor {
    left: 4.8rem;
  }

  > .banner-download:hover + .banner-download-hover {
    display: block;
  }

  > .banner-download-hover:hover {
    display: block;
  }
}

#detail {
  position: relative;
  padding-bottom: 1.94rem;
  overflow: hidden;

  > .detail-item {
    position: relative;
    height: 4.93rem;
    margin-top: 2.4rem;

    > .img {
      position: absolute;
      top: 0;
      width: auto;
      height: 4.93rem;
    }

    > .info {
      position: absolute;
      top: 0;
      width: 3.5rem;
      height: 4.93rem;

      > .title {
        position: absolute;
        left: 0;
        top: 1.55rem;
        line-height: .42rem;
        font-size: .44rem;
        font-weight: bold;
      }

      > .desc {
        position: absolute;
        left: .04rem;
        line-height: .42rem;
        font-size: .32rem;
        top: 2.24rem;
        color: #555;
      }

      > .symbol {
        position: absolute;
        left: 0;
        top: 3.3rem;
        width: 0.44rem;
        height: 0.09rem;
      }
    }
  }

  > .detail-circle {
    position: absolute;
    z-index: -2;
    width: 10.48rem;
    height: 8.97rem;
  }
}
</style>
